<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("view.role_name") }}
      </h4>
      <div class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="roleLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>
        <select
          v-if="!roleLoading"
          id="role"
          class="form-control"
          v-model="row.role"
          v-on:change="onRoleChange"
        >
          <option v-for="(role, idx) in roles" :key="idx" :value="role.name">
            {{ role.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "CardRole",
  props: ["role", "roleWhereNotIn"],
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        role: this.$props.role,
      },
      roles: [],
      roleLoading: true,
    };
  },
  mounted() {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRoles();
  },
  methods: {
    onRoleChange() {
      const data = {
        role: this.row.role,
      };
      this.$emit("roleChange", data);
    },

    fetchRoles() {
      this.roleLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/roles",
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 100,
          whereNotIn: this.$props.roleWhereNotIn,
        },
      };
      this.axios(options)
        .then((res) => {
          this.roleLoading = false;
          this.roles = res.data.data.rows;
        })
        .catch((err) => {
          this.roleLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>
